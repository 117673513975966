.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.App-header {
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  color: teal;
  transform: translateY(-31.9%); /* Approximation to position text at golden ratio */
  font-size: 2vh;
}
